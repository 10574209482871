import React, { useEffect, useState } from 'react'
import { getAttendeeOtp, submitAttendeeOtp } from '../api/attendeeApi';
import { useParams } from 'react-router-dom';
import { getCourse } from '../api/courseApi';

const CheckInCoursePage = () => {

  const {courseId} = useParams()
  const [userData, setUserData] = useState({
    phoneNumber:"",
    ID:"",
    otp:""
  });
  const [courseDetails, setCourseDetails] = useState({
    courseName:"",
    location:"",
    notFound:false
  });
  const [gotOtp, setGotOTP] = useState(false);
  const [otpApproved, setOtpApproved] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    getCourse(courseId)
      .then((res) => {setCourseDetails({...res, notFound: false})})
      .catch(err => {
        console.log(err);
        setCourseDetails(courseDetails => ({...courseDetails, notFound:true}))
      })
  }, [])

  const submitOtpRequest = async () => {
    try {
      if(!userData.phoneNumber) {
        return setError("מספר טלפון שגוי")
      }
      const resData = await getAttendeeOtp(userData);
      setGotOTP(true);
    } catch (err) {
      setError("שגיאה בהכנסת נתונים אנא נסה שנית");
      setTimeout(() => {
        setError("");
      }, 2000)
    }
  }

  const submitOTP = async () => {
    const data = {...userData, courseId};
    try {
      const resData = await submitAttendeeOtp(data);
      setCourseDetails(resData);
      setOtpApproved(true);
    } catch (error) {
      setError("שגיאה בהכנסת סיסמה הנה נסה שנית");
      setTimeout(() => {
        setError("");
      }, 2000)
    }
  }

  if(courseDetails.notFound) {
    return <div className='flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#b0d8e7] w-full gap-2'>
      <div className='p-5 rounded-lg bg-white justify-center items-center flex flex-col w-[90%] md:w-[30%] shadow-md'>
      <h2 className='text-black font-extrabold text-3xl'>קורס לא נמצא</h2>
      </div>
    </div>
  }
  return (
    <div className='flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#b0d8e7] w-full gap-2'>
      <div className='p-5 rounded-lg bg-white flex flex-col w-[90%] md:w-[30%] shadow-md'>
          <div className='w-full flex flex-col justify-center items-center'>
            <h2 className='text-black font-semibold text-lg'> {courseDetails.courseName}</h2>
            <h2 className='text-black font-semibold text-lg'> מיקום: {courseDetails.location}</h2>

          </div>
          {!gotOtp && !otpApproved && <>
            <div className="mb-2 flex flex-col items-center w-full">
              <label
                className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
              >
              מספר טלפון
              </label>
              <div className="relative flex items-start w-full">
                <input
                  type="text"
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  onChange={(e) => setUserData(userData => ({...userData, "phoneNumber":e.target.value}))}
                  className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
                />
              </div>
            </div>
            
            <div className="mb-2 flex flex-col items-center w-full">
              <label
                className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
              >
                תעודת זהות
              </label>
              <div className="relative flex items-center w-full">
                <input
                  type="text"
                  name="ID"
                  value={userData.ID}
                  onChange={(e) => setUserData(userData => ({...userData, "ID":e.target.value}))}
                  className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
                />
              </div>
              {error && <h2 className='text-red-400 font-semibold text-md'>{error}</h2>}
            </div>
            <div className="mt-6 flex flex-row justify-center items-center">
              <button
                className="w-[100%] px-4 py-2 tracking-wide text-white font-semibold
                  transition-colors duration-200 transform bg-[#43A6FB] rounded-md hover:bg-[#3396CB]
                  focus:outline-none focus:bg-[#3396GB]"
                onClick={submitOtpRequest}
              >
                קבל סיסמה חד פעמית
              </button>
            </div>
          </>}
          {!otpApproved && gotOtp && <>
            <div className="mb-2 flex flex-col items-center w-full">
              <label
                className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
              >
                סיסמה חד פעמית
              </label>
              <div className="relative flex items-start w-full">
                <input
                  type="text"
                  name="otp"
                  value={userData.otp}
                  onChange={(e) => setUserData(userData => ({...userData, "otp":e.target.value}))}
                  className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
                />
              </div>
            </div> 
            {error && <h2 className='text-red-400 font-semibold text-md'>{error}</h2>}
            <div className="mt-6 flex flex-row justify-center items-center">
              <button
                className="w-[100%] px-4 py-2 tracking-wide text-white font-semibold
                  transition-colors duration-200 transform bg-[#43A6FB] rounded-md hover:bg-[#3396CB]
                  focus:outline-none focus:bg-[#3396GB]"
                onClick={submitOTP}
              >
                שלח סיסמה חד פעמית
              </button>
            </div>
          </>}
        {otpApproved && gotOtp && <div className='flex flex-col gap-2 justify-center items-center'>
          <h2 className='text-black font-bold text-2xl'>הגעתך לסמינר נרשמה בהצלחה</h2>
        </div>}
      </div>
    </div>
  )
}

export default CheckInCoursePage