import React, { useEffect, useState } from 'react'
import UserIcon from '../static/user.png';
import LockIcon from '../static/lock.png';
import { loginAdmin } from '../api/authApi';
import { loginUser } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [login, setLogin] = useState({
    email:"",
    password:""
  });
  const [error, setError] = useState("");


  const sendLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await loginAdmin(login);
      if(res?.msg == "Authentication Email sent") {
        return navigate("/emailSent");
      }
      localStorage.setItem("token", res.token);
      dispatch(loginUser(res));
    } catch (error) {
      console.log(error)
      if(error.response.data.msg === "user doesn't exist" || error.response.data.msg === "Password is incorrect") {
        setError("שם משתמש/סיסמה שגויה");
      }
      else {
        setError("שגיאה לא צפיוה, הנה נסו מאוחר יותר");
      }
      setTimeout(() => setError(""), 2000)
    }
  }

  const onChange = (e) => {
    setLogin(login => ({...login, [e.target.name]: e.target.value}));
  }

  useEffect(() => {
    if(user.token) {
      navigate('/')
    }
  }, [user])
  
  return (
    <div className='relative flex flex-col justify-center min-h-screen overflow-scroll bg-[#2D5186] w-full gap-5'>
      <div className='flex flex-col justify-center items-center mt-15'>
      </div>
      <div className="flex flex-col justify-center items-center md:w-[30%] p-6 m-auto mt-2 rounded-md shadow-md lg:max-w-xl bg-white">
        <form className="mt-6 w-[80%]" onSubmit={sendLogin}>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              שם משתמש
            </label>
            <div className="relative flex items-center">
              <img src={UserIcon} className="absolute" width={17}/>
              <input
                type="text"
                name="email"
                className="block w-full h-10 pl-8  text-black bg-white border-b-2 border-black  focus:ring-none outline-none"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              סיסמה
            </label>
            <div className="relative flex items-center">
              <img src={LockIcon} className="absolute"/>
              <input
                type="password"
                name="password"
                className="block w-full h-10 pl-8 text-black bg-white border-b-2 border-black  focus:ring-none outline-none"
                onChange={onChange}
              />
            </div>
          </div>
          {error && <h2 className='text-red-400 font-semibold text-md'>{error}</h2>}
          <div className="mt-6 flex flex-row justify-center items-center">
            <button
              className="w-[50%] px-4 py-2 tracking-wide text-white font-semibold
                transition-colors duration-200 transform bg-[#43A6FB] rounded-md hover:bg-[#3396CB]
                focus:outline-none focus:bg-[#3396GB]"
              type="submit"
            >
              כניסה
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginPage