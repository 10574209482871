import axios from 'axios';

export const sendZkongBind = async (token, data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/zkong/`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}