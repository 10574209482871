import axios from 'axios';

export const getAttendeeOtp = async (userData) => {
  const res = await axios.post(`https://seahorse-app-c3rmx.ondigitalocean.app/attendee/getOTP`, userData);
  return res.data;
}

export const submitAttendeeOtp = async (data) => {
  const res = await axios.post(`https://seahorse-app-c3rmx.ondigitalocean.app/attendee/checkOTP`, data);
  return res.data;
}