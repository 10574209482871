import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-tailwindcss-select';
import { getTrailLogs } from '../api/trailLogApi';
import TrailLog from '../components/TrailLog';
import Sidebar from '../components/layout/Sidebar';

const TimeSelection = [
  {value:1, label:"1 day ago"},
  {value:2, label:"2 days ago"},
  {value:7, label:"1 week ago"},
  {value:14, label:"2 week ago"},
  {value:31, label:"1 month ago"}

]

const TimeLookup = {
  1:"1 day ago",
  2:"2 days ago",
  7:"1 week ago",
  14:"2 weeks ago",
  31:"1 month ago"
}

const TraillogPage = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [searchParams, setSearchParams] = useState({
    days:1,
  });
  const [logs, setLogs] = useState([]);

  const fetchTrailLogs = async () => {
    try {
      const res = await getTrailLogs(user.token, searchParams);
      setLogs(res);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    console.log(user);
    if(!user.token) {
      navigate("/login");
    }
    fetchTrailLogs();
  }, [])
  return (
    <div className="flex flex-row justify-start">
      <Sidebar/>
      <div className='h-screen w-full bg-gray-100 flex flex-col items-center overflow-scroll gap-4 p-2'>
        <div className='bg-white p-5 flex flex-col  shadow-md w-[80%]  justify-center gap-4 items-center rounded-lg'>
          <h2 className='font-bold text-2xl'>Trail Logs</h2>
          <div className='flex flex-row mt-5 justify-center w-full'>
            <div className=' flex flex-row  gap-2 items-center '>
              <h2 className='text-black font-bold text-xl '>Email:</h2>
              <input
                type="text"
                name="name"
                value={searchParams.userEmail}
                onChange={(e) => {
                setSearchParams(searchParams => ({...searchParams, "userEmail":e.target.value}))
                }}
                className=" text-black  border-[1px] border-[#c7c7c8] focus:ring-none outline-none p-2 w-[330px]"
              />
            </div>
            <div className='flex flex-row gap-2 justify-center items-center px-4'>
              <h2 className='text-black font-bold text-xl'>Time:</h2>
              <Select
                options={TimeSelection}
                value={{value:TimeLookup[searchParams.days], label:TimeLookup[searchParams.days]}}
                onChange={(selection)=> {
                  setSearchParams(searchParams => ({...searchParams, days: selection.value}))
                }}
                classNames={{
                  menu:"absolute w-[330px] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[330px] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
          </div>
          <div className='flex-1 flex flex-row gap-2 justify-center items-center px-4'>
            <button 
              className='bg-blue-500 text-white font-bold
                rounded-lg hover:bg-blue-400 active:bg-blue-300 p-3'
              onClick={() => {
                fetchTrailLogs();
              }}
              >
              Search
            </button>  
          </div>
        </div>
        <div className='flex flex-col justify-center w-[80%] gap-5 mt-2 '>
          {logs && logs.map(log => <TrailLog log={log} key={log._id}/>)}
        </div>

      </div>
    </div>
  )
}

export default TraillogPage