import axios from 'axios';

export const getOrgLocations = async (token) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/organization/locations`,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}