import React from 'react'

const EmailSentPage = () => {
  return (
    <div className='relative flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#2D5186] w-full gap-5'>
      <div className="flex flex-col h-full w-[40%] justify-center items-center border-2 bg-white rounded-lg shadow-lg p-4">
        <h2 className='font-bold text-black text-xl'>כרגע נשלח אליך מייל עם לינק להתחברות</h2>
      </div>
    </div>
  )
}

export default EmailSentPage