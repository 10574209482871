import axios from 'axios';

export const getAttendence = async (token, params) => {
  const res = await axios.get(`https://seahorse-app-c3rmx.ondigitalocean.app/seminar/getAttendence`,
    {
      params,
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}