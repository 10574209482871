import { configureStore, createSlice } from "@reduxjs/toolkit";


const initialUserState = {
  loading: false,
  id: null,
  token: null,
  email: null,
}

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUserLoading(state) {
      state.loading = true;
    },

    loginUser(state, action) {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.id = action.payload.userId;
      state.loading = false;
    },

    logoutUser(state) {
      state = initialUserState;
    }
  }
});

export const {
  loginUser,
  logoutUser,
  setUserLoading

} = userSlice.actions;

export const store = configureStore({
  reducer: {
    user: userSlice.reducer
  }
})