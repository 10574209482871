import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { createAttendence, getCourse, getCourseById, updateCourseEnd} from '../api/courseApi';
import Select from 'react-tailwindcss-select';
import MainLogo from '../assets/logo.png';
import moment from 'moment-timezone';
import { getSeminarLocations } from '../api/seminarApi';

const AttendenceCourseEnd = () => {

  const {courseId, location} = useParams();
  const [locations, setLocations]  = useState([]);
  const [userData, setUserData] = useState({
    ID:localStorage.getItem("userId") || "",
    firstname:localStorage.getItem("firstname") || "",
    lastname:localStorage.getItem("lastname") || "",
    courseId: courseId,
    type:"end"
  });
  const [courseDetails, setCourseDetails] = useState({
    courseName:"",
    location:"",
    notFound:false
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getCourseById(courseId)
      .then((res) => {
        setCourseDetails({...res, notFound: false})
        getSeminarLocations(res.seminarId).then(res => setLocations(res));
      })
      .catch(err => {
        console.log(err);
        setCourseDetails(courseDetails => ({...courseDetails, notFound:true}))
      })
  }, [])

  const  updateCourse = async () => {
    try {
      if(!userData.ID || !userData.firstname || !userData.lastname || !userData.location) {
        console.log(userData);
        return setError("שגיאה בהכנסת נתונים אנא נסה שנית");
      }
      // if(!(/[\u0590-\u05FF]+$/.test(userData.firstname)) ||!(/[\u0590-\u05FF]+$/.test(userData.lastname))) {
      //   return setError("אנא רשום את שמך בעברית")
      // }
      // if(!(/^\d+$/.test(userData.ID))) {
      //   return setError("נא להכניס מספרים בלבד בשדה ת״ז")
      // }
      await createAttendence(userData);
      setTimeout(() => {
        window.location.replace("https://www.facebook.com/p/%D7%94%D7%A2%D7%9E%D7%95%D7%AA%D7%94-%D7%9C%D7%A7%D7%99%D7%93%D7%95%D7%9D-%D7%9E%D7%A7%D7%A6%D7%95%D7%A2%D7%99-%D7%97%D7%91%D7%A8%D7%AA%D7%99-100064562234744/?locale=he_IL");
      }, 3000);
      localStorage.setItem("userId", userData.ID);
      localStorage.setItem("firstname", userData.firstname);
      localStorage.setItem("lastname", userData.lastname);
      return setSuccess(true);
    } catch (err) {
      console.log(err);
      setError("שגיאת חיבור אינטרנט");
    } finally {
      setTimeout(() => {
        setError("")
      }, 3000)
    }
  }

  if(courseDetails.notFound) {
    return <div className='flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#b0d8e7] w-full gap-2'>
      <div className='p-5 rounded-lg bg-white justify-center items-center flex flex-col w-[90%] md:w-[30%] shadow-md'>
      <h2 className='text-black font-extrabold text-3xl'>קורס לא נמצא</h2>
      </div>
    </div>
  }
  return (
    <div dir='rtl' className='flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#b0d8e7] w-full gap-2'>
      <img
        src={courseDetails.coursePhoto || MainLogo}
        className='w-full h-[25%] md:w-[30%] md:h-[20%]'
      />
      <div className='px-[20px] pr-[25px] py-[25px] rounded-lg bg-white flex flex-col w-[90%] md:w-[30%] shadow-md'>
        {!success && <>
          <div className='justify-center items-center flex flex-col mb-5'>
            <h2 className='text-black font-extrabold text-xl'>אישור נוכחות סוף הרצאה</h2>
          </div>
          <div className='relative flex flex-col items-start w-full mb-2'>
            {/* <p
              
              className="flex flex-row justify-start text-md font-semibold text-gray-800"
            >
              מיקום: {courseDetails.location}
            </p>
             */}
          </div>
          <div className='w-full flex flex-col mb-10'>
            <h2 className='text-gray-800 font-semibold text-md  w-full'> מספר הרצאה: {courseDetails.courseId}</h2>
            {/*
            <h2 className='text-gray-800 font-semibold text-md'>שעת סיום: {new Date(courseDetails.endTime).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"})}</h2> */}
            <h2 className='text-black font-semibold text-sm'>נוכחות בהרצאה חובה</h2>
          </div>
        </>}
        {!success && <>
          <div className="mb-2 flex flex-col w-full">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
              בחר מלון
            </label>
              <Select
                options={(() => {
                  return locations.map(loc => ({value: loc, label: loc}))
                })()}
                value={userData.location ? {value: userData.location, label: userData.location} : {value:"בחר מיקום", label:"...בחר מיקום"}}
                onChange={(selection)=> {
                  setUserData(userData => ({...userData, location: selection.value}))
                }}
                classNames={{
                  menu:"w-full absolute w-[100%] bg-white border-2 border-gray overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[100%]  bg-white border-2 border-gray px-2 justify-between z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
          </div>
          <div className="mb-2 flex flex-col w-full">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
              שם פרטי
            </label>
            <div className="relative flex items-start w-full">
              <input
                type="text"
                name="firstname"
                value={userData.firstname}
                onChange={(e) => setUserData(userData => ({...userData, "firstname":e.target.value}))}
                className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
              />
            </div>
          </div>
          <div className="mb-2 flex flex-col w-full">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
              שם משפחה
            </label>
            <div className="relative flex items-start w-full">
              <input
                type="text"
                name="lastname"
                value={userData.lastname}
                onChange={(e) => setUserData(userData => ({...userData, "lastname":e.target.value}))}
                className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
              />
            </div>
          </div>
          <div className="mb-2 flex flex-col w-full">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
              תעודת זהות
            </label>
            <div className="relative flex items-start w-full">
              <input
                type="text"
                name="ID"
                value={userData.ID}
                onChange={(e) => setUserData(userData => ({...userData, "ID":e.target.value}))}
                className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
              />
            </div>
          </div> 
        
          {error && <h2 className='text-red-400 font-semibold text-md'>{error}</h2>}
          <div className="mt-6 flex flex-row justify-center items-center">
            <button
              className="w-[100%] px-4 py-2 tracking-wide text-white font-semibold
                transition-colors duration-200 transform bg-[#43A6FB] rounded-md hover:bg-[#3396CB]
                focus:outline-none focus:bg-[#3396GB]"
              onClick={updateCourse}
            >
              שלח
            </button>
          </div>
        </>}
        {success && <div className='flex flex-col gap-2 justify-center items-center'>
          <h2 className='text-black font-bold text-xl w-full'>אישור נוכחות סוף הרצאה</h2>
        </div>}
      </div>
    </div>
  )
}

export default AttendenceCourseEnd