import axios from "axios";

export const getTrailLogs = async (token, params) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/trailLogs/`,
    {
      headers:{
        token: `bearer ${token}`
      },
      params
    }
  );
  return res.data;
}