
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Device from '../../static/device.png';
import Licenses from '../../static/key.png';
import Display from '../../static/developer_mode_tv.png';
import Template from '../../static/data_table.png';
import Porduct from '../../static/product.png';
import Scan from '../../static/scan.png'
import Printer from '../../static/printer.png';

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  return (
    <div className="border-r-2 h-full hidden md:flex">
    <div className="flex flex-col p-3 bg-white  w-20 md:w-80 h-screen border">
      <div className="flex flex-col justify-center items-center space-y-3 mt-5">
        <div className="flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
          <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Smart Attendence</h2>
        </div>
        <div className="flex flex-col justify-center items-center text-gray-400">
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start">
            <li className="rounded-sm">
              <Link
                to="/"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Display} width={30} alt=''/>
                <span className="hidden md:block font-bold">סמינרים</span>
              </Link>
            </li>
          </ul>
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start">
            <li className="rounded-sm">
              <Link
                to="/trailLog"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Display} width={30} alt=''/>
                <span className="hidden md:block font-bold">רשימת פעולות אחרונות</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Sidebar