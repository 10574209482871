import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CoruseItem from '../components/CoruseItem';
import { getAllCourses } from '../api/courseApi';
import Sidebar from '../components/layout/Sidebar';
import { getAllSeminars } from '../api/seminarApi';
import SeminarItem from '../components/seminar/SeminarItem';

const SeminarsPage = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [seminars, setSeminars] = useState([]);
  
  useEffect(() => {
    if(!user.token) {
      navigate('/login')
    }
    else {
      getAllSeminars(user.token)
        .then(res => {
          console.log(res);
          setSeminars(res);
        })
    }
  }, [])
  

  return (
    <div className="flex flex-row justify-start">
      <Sidebar/>
      <div dir={"ltr"} className='h-screen w-full bg-white flex flex-col items-center overflow-scroll gap-4 p-2'>
        <div className='w-full justify-end flex'>
          <Link to="/addSeminar">
            <button className='p-2 bg-blue-400 text-white font-bold rounded-md shadow-md'>
              הוסף סמינר
            </button>
          </Link>
        </div>
        {seminars && seminars.length > 0 && 
          <div 
            className='bg-white p-2 flex flex-col 
                w-full justify-start gap-4 items-center  overflow-scroll'
          >
          {seminars.map(seminar => <SeminarItem seminar={seminar} key={seminar._id}/>)}
        </div>}
      </div>
    </div>
  )
}

export default SeminarsPage