import LoadingIcon from '../../static/loading.gif';

const LoadingModal = () => {
  return (
    <div className='modal bg-black opacity-60'>
      <div className="relative w-auto my-6 mx-auto max-w-3xl ">
        <div className='rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none p-9 justify-center items-center'>
          <img src={LoadingIcon} className='w-[50%] h-[50%]'/>
        </div>
      </div>
    </div>
  )
}

export default LoadingModal