import axios from 'axios';

export const loginAdmin = async (data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/auth/login`,
    data
  );
  return res.data;
}

export const authAdmin = async (token) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/auth/`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const validateAuthToken = async (data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/auth/validate`,
    data
  );
  return res.data;
}