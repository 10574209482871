import React from 'react'
import { Link } from 'react-router-dom'
import ArrowLeft from '../../static/arrow-left.png'
const SeminarItem = ({seminar}) => {
  return (
    <Link to={`/courses/${seminar._id}`}  dir={"rtl"} className='relative flex flex-row justify-end items-start bg-white p-4 gap-3 border-b-2 border-gray-200 w-[90%]'>
    <>
      <div className='flex flex-col justify-start items-start gap-2 w-full '>
        <h2 className='text-black font-bold text-2xl'> {seminar.seminarName}</h2>
        <h2 className='text-gray-500 font-semibold text-xl'>
           תחילת סמינר:  {new Date(seminar.startDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"})}
        </h2>
        <h2 className='text-gray-500 font-semibold text-xl'>
          תחילת סוף: {new Date(seminar.endDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"})}
        </h2>

      </div>
        <button className='flex flex-col justify-center items-center mt-10'>
          <img src={ArrowLeft} width={25}/>
        </button>
    </>
    </Link>
  )
}

export default SeminarItem