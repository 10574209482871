import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/CoursesPage';
import CheckInCoursePage from './pages/CheckInCoursePage';
import AttendenceCourseStart from './pages/AttendenceCourseStart';
import AttendenceCourseEnd from './pages/AttendenceCourseEnd';
import LoginPage from './pages/LoginPage';
import { useEffect } from 'react';
import { authAdmin } from './api/authApi';
import { useDispatch } from 'react-redux';
import { loginUser } from './store';
import QrCodePage from './pages/QrCodePage';
import QrCodeDisplay from './pages/QrCodeDisplay';
import SeminarsPage from './pages/SeminarsPage';
import CoursesPage from './pages/CoursesPage';
import AddSeminarPage from './pages/AddSeminarPage';
import AddCoursePage from './pages/AddCoursePage';
import EditSeminarPage from './pages/EditSeminarPage';
import EmailSentPage from './pages/EmailSentPage';
import AuthEmailPage from './pages/AuthEmailPage';
import TraillogPage from './pages/TraillogPage';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");

    if(!token) {
      return;
    }

    authAdmin(token)
      .then(res => {
        dispatch(loginUser({...res, token}));
      })
      .catch(err => console.log(err))
    
  }, []);

  return (
    <div className='w-full h-screen'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SeminarsPage/>}/>
          <Route path='/courses/:seminarId' element={<CoursesPage/>}/>
          <Route path='/getotp/:courseId' element={<CheckInCoursePage/>}/>
          <Route path='/attendenceStart/:courseId' element={<AttendenceCourseStart/>} />
          <Route path='/attendenceEnd/:courseId' element={<AttendenceCourseEnd/>} />
          <Route path='/getQrCode/:courseId/:type' element={<QrCodePage/>}/>
          <Route path='/displayQrCode/:qrLink/:type' element={<QrCodeDisplay/>}/>
          <Route path='/login' element={<LoginPage/>}/>
          <Route path='/addSeminar' element={<AddSeminarPage/>}/>
          <Route path='/editSeminar/:seminarId' element={<EditSeminarPage/>}/>
          <Route path='/createCourse/:seminarId' element={<AddCoursePage/>}/>
          <Route path='/emailsent' element={<EmailSentPage/>}/>
          <Route path="/emailAuth/:loginId/:loginToken" element={<AuthEmailPage/>} />
          <Route path="/trailLog" element={<TraillogPage/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
