import axios from 'axios';

export const getAllSeminars = async (token) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/`,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const getSeminar = async (token, seminarId) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/getSeminar?seminarId=${seminarId}`,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const createSeminar = async (token, seminar) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/`,
    seminar,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}
export const editSeminar = async (token, seminar) => {
  const res = await axios.put(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/`,
    seminar,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const uploadSeimnarImage = async (token, data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/uploadPhoto`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getSeminarLocations = async (seminarId) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/seminar/getSeminarLocations?seminarId=${seminarId}`
  )
  return res.data;
}