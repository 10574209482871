import React, { useEffect, useState } from 'react'
import LoadingIcon from '../static/loading.gif';
import { useNavigate, useParams } from 'react-router-dom';
import { validateAuthToken } from '../api/authApi';
import { useDispatch } from 'react-redux';
import { loginUser } from '../store';

const AuthEmailPage = () => {
  const [loginFailed, setLoginFailed] = useState(false);
  const {loginId, loginToken} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateToken = async () => {
    try {
      const res = await validateAuthToken({
        loginId,
        loginToken
      });
      localStorage.setItem("token", res.token);
      dispatch(loginUser(res));
      navigate("/");
    } catch (err) {
      setLoginFailed(true);
    }
  }

  useEffect(() => {
    validateToken();
  }, [loginId,loginToken]);

  return (
    <div className='relative flex flex-col justify-center items-center min-h-screen overflow-scroll bg-[#2D5186] w-full gap-5'>
      <div className="flex flex-col h-full w-[40%] justify-center items-center border-2 bg-white rounded-lg shadow-lg p-4">
        {!loginFailed && <>
          <h2 className='font-bold text-black text-xl'>מתחבר למערכת...</h2>
          <img src={LoadingIcon} className='w-[50%] h-[50%]'/>
        </>}
        {loginFailed && <>
          <h2 className='font-bold text-red-500 text-xl'>פרטי התחברות שגויים</h2>
          <button className='bg-blue-400 rounded-lg p-2 text-white font-bold' onClick={() => {navigate("/")}}>
            חזרה
          </button>
        </>}
      </div>
    </div>
  )
}

export default AuthEmailPage