import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CoruseItem from '../components/CoruseItem';
import { getAllCourses } from '../api/courseApi';
import Sidebar from '../components/layout/Sidebar';
import { getSeminar } from '../api/seminarApi';
import SeminarHeader from '../components/seminar/SeminarHeader';
import StaticQRModal from '../components/modals/StaticQRModal';
import { getAttendence } from '../api/attendenceApi';

const CoursesPage = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const {seminarId} = useParams();
  const [courses, setCourses] = useState([]);
  const [seminar, setSeminar] = useState();
  const [StaticCourseId, setStaticCourseId] = useState(null);
  const [staticCourseType, setStaticCourseType] = useState(null);
  const [pagination, setpagination] = useState({
    page: 0,
    loadMore: true,
    locationFilter:""
  });


  const increasePagination = async () => {
    const coursesRes = await getAllCourses(user.token, seminarId, pagination.page +6, pagination.locationFilter);
    
    console.log(coursesRes);
    setCourses(coursesRes.courses);
    setpagination(pagination => ({...pagination, page: pagination.page + 6, loadMore:coursesRes.moreToLoad}))
  }

  const addLocationFilter = async (locationFilter) => {
    const coursesRes = await getAllCourses(user.token, seminarId, pagination.page +6, locationFilter);
    setCourses(coursesRes.courses);
    setpagination(pagination => ({...pagination, locationFilter:locationFilter}));  
  }

  const getData = async () => {
    try {
      const seminarRes = await getSeminar(user.token, seminarId);
      setSeminar(seminarRes);
      const coursesRes = await getAllCourses(user.token, seminarId, pagination.page, pagination.locationFilter);
      setCourses(coursesRes.courses);
      setpagination(pagination => ({...pagination, loadMore:coursesRes.moreToLoad}));
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if(!user.token) {
      navigate('/login')
    }
    else {
      getData();
    }
  }, [user])
  
  const setStaticQRCodeModal = (id, type) => {
    setStaticCourseId(id);
    setStaticCourseType(type);
  }

  return (
    <div className="flex flex-row justify-start">
      <Sidebar/>
      <div id='coursesPageElem' dir={"ltr"} className='h-screen w-full bg-white flex flex-col items-center overflow-scroll gap-4 p-2'>
        {StaticCourseId && staticCourseType && <StaticQRModal courseId={StaticCourseId} type={staticCourseType} closeModal={() => setStaticQRCodeModal(null, null)}/>}
        {seminar && <SeminarHeader seminar={seminar} addLocationFilter={addLocationFilter}/>}
        {courses && courses.length > 0 && 
          <div className='grid grid-cols-1 lg:grid-cols-3 w-full justify-start items-start gap-5 p-5'>
          {courses.map(course => <CoruseItem courseId={course.courseId} courseName={course.courseName} location={course.location} courseInnerId={course._id} key={course._id} setStaticQRCodeModal={setStaticQRCodeModal} />)}
        </div>}
        <div className='w-full bg-white flex flex-col justify-center items-center'>
          <button 
            className={`bg-blue-400 p-3 shadow-md rounded-md
              hover:bg-blue-500 transition-all ${!pagination.loadMore ? "opacity-30" :""}`}
            onClick={increasePagination}
          >
            <h2 className='text-white font-bold'>
              טען עוד
            </h2>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CoursesPage