import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createQrLink, createQrLinkById } from '../api/courseApi';
import QRCode from 'react-qr-code'
import MainLogo from '../assets/logo.png';
import { getSeminar } from '../api/seminarApi';

const QrCodePage = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [courseQR, setCourseQR] = useState({});
  const {courseId, type} = useParams();
  const [timer, setTimer] = useState(10);
  useEffect(() => {
    if(!user.token) {
      navigate('/login');
    }
  }, [user])

  const getCourseQr = async (token) => {
    try {
      let res = await createQrLinkById(
        token,
        {
          "id": courseId
        }
      );
      console.log(res)
      setCourseQR(res);

    } catch (err) {
      console.log(err);
    }       
  }

  useEffect(() => {
    if(user.token) {
      getCourseQr(user.token);
      const intervalId =  setInterval(() => {
        setTimer(time => {
          if(time === 0) {
            getCourseQr(user.token);
            return 10;
          }
          if(time >= 1) {
            return time-1;
          }
         });
      }, 1000);
      return () => {
        clearInterval(intervalId);
      }
    }
  }, [user])

  return (
    <div dir='rtl' className='w-full h-full flex justify-start'>
      <img
        src={courseQR.coursePhoto || MainLogo}
        className='h-[100%] w-[55%]'
      />
      {courseQR && <div className='w-[45%] h-full flex flex-col justify-start items-center p-2'>
        <h2 className='text-black font-bold text-3xl mt-10'>מספר הרצאה: {courseQR.courseId}</h2>
        <h2 className='text-gray-600 font-bold text-2xl'>מיקום הרצאה: {courseQR.location}</h2>
        <p className='text-gray-600 font-bold max-w-[75%]'>
          הרישום להרצאות מתבצע בצורה דיגיטלית, בתחילה ובסיום. יש להגיע בזמן להרצאה. לא יינתן רישום באיחור 
        </p>
        <QRCode
          className='mt-5'
          size={256}
          value={`${window.location.origin}/displayQrCode/${courseQR?.qrLink}/${type}`}
        />
        <p className='mt-5 text-black text-3xl font-bold'>
          מספר שניות עד לחילוף קוד:  {timer}
        </p>
      </div>}
    </div>
  )
}

export default QrCodePage